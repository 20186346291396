import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import ChildForm from '@components/forms/ChildForm'
import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { permissionValid } from '@utils/utils/util'
import { NumOfChildrenNeedingSchoolContext } from '@pages/Children/contexts/NumOfChildrenNeedingSchool'

import '../styles.css'
import AddSchoolDialogHandler from '../AddSchoolDialogHandler'
import SendToMemberPoolDialogHandler from '../SendToMemberPoolDialogHandler'
import ConfirmDeleteDialogHandler from '@components/dialogs/ConfirmDeleteDialogHandler'



export interface TableProps {
	action: CRUDAction
}

const gender_map: Record<number, string>  = {
	1: 'M',
	2: 'F', 
	3: 'Other'
}

const shirt_map: Record<number, string>  = {
	1: 'Extra small',
	2: 'Small',
	3: 'Medium', 
	4: 'Large',
	5: 'Extra large'
}

const valueGetter = (params: any) => {
	if (params.field === 'sex')
		return gender_map[params.value] || ''

	else if (params.field === 'shirt_size')
		return shirt_map[params.value] || ''
}

const ChildNeedsSchoolTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('children')

	const user = getLocalStorageUser()

	const {setNumOfChildrenNeedingSchool} = useContext(NumOfChildrenNeedingSchoolContext)

	const can_transfer = permissionValid(user, UserPermissions.TRANSFER_CHILDREN)
	const can_delete = permissionValid(user, UserPermissions.DELETE_CHILDREN)

	const columns = [
		{ field: 'id', headerName: t('id') },
		{ field: 'username', headerName: t('name'), minWidth: 200 },
        { field: 'school_from_minatix', sortable: false, headerName: t('typed_school'), minWidth: 250 },
		{ field: 'age', sortable: false, filterable: false, headerName: t('age') },
		{ field: 'parent_email', sortable: false, headerName: t('parents_email'), minWidth: 200 },
		{ field: 'parent_phone', sortable: false, headerName: t('phone_no'), minWidth: 200 },
		{ field: 'sex', sortable: false, filterable: false, headerName: t('gender'), cellClassName: 'center-aligned-cell', maxWidth: 85, valueGetter },
		{ field: 'registered', sortable: false, filterable: false, headerName: t('registered') },
		{ field: 'birthday', sortable: false, filterable: false, headerName: t('birthday') },
	]


	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'parent_email') {
			field = 'preferred_parent_contact__email'
		}
		else if (field === 'parent_phone') {
			field = 'preferred_parent_contact__phone_number'
		}
		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		return field
	}

    return (
        <Table
		dialogs={
			[
				...(can_transfer ? [AddSchoolDialogHandler] : []),  
				(props) => SendToMemberPoolDialogHandler({ ...props, setNumState: setNumOfChildrenNeedingSchool }),
				...(can_delete ? [ConfirmDeleteDialogHandler] : [])  
			]
		}
			action={action} 
			Form={ChildForm} 
			columns={columns} 
			filter={{has_school: false, archive: false, is_2024: true}}
			use_pagination={true}
			page_size={100}
			paginationFilterParser={paginationFilterParser}
			paginationSortParser={paginationSortParser}
			amount_of_rows_selection={[100]}
			
		/>
    )
}

export default ChildNeedsSchoolTable