import {useState, useContext} from 'react'

import Typography from '@mui/material/Typography'
import FormControlLabel  from '@mui/material/FormControlLabel'
import Checkbox  from '@mui/material/Checkbox'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import FranchisorRoyaltyActions from '@actions/Finance/FranchisorInvoiceActions/franchisorInvoiceActions'
import { Button } from '@mui/material'
import { getLocalStorageCompany } from '@utils/localStorage/company'


const ConfirmRoyaltiesSendDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const company = getLocalStorageCompany()

	const {setMessage} = useContext(MessageContext)

	const [disable_button, setDisableButton] = useState(false)
	const [only_xero, setOnlyXero] = useState(company.is_xero_company ? false : true)

	const handleSend = () => {
		setDisableButton(true)
		const invoices = state_manager.data.filter(invoice => ids.includes(invoice.id))
		console.log(invoices)
		const data = {
			invoices: invoices,
			only_xero: only_xero
		}
		const royaly_actions = new FranchisorRoyaltyActions()
		royaly_actions.sendRoyalties(data)
		.then(data => {
			console.log(data)
			state_manager.setGetAction(undefined, undefined, {'approved': 'False'})
			handleClose()
			setDisableButton(false)
			setMessage(props => ({...props, success: "Invoices Sent"}))
		})
		.catch(error => {
			setMessage(props => ({...props, error: error.message}))
			setDisableButton(false)
		})
	}

	const handleClose = () => {
		onClose()
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOnlyXero(event.target.checked)
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Confirm Send </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
				Are you sure you want to send {ids.length} {ids.length === 1 ? <span>item to franchise</span> : <span>items to franchises</span>} {company.is_xero_company && <span> and to Xero</span>}?
			</Typography>
			<br/>
			{company.is_xero_company &&
				<FormControlLabel 
					control={
						<Checkbox 
							checked={only_xero} 
							onChange={handleChange} 
						/>
					} 
					label={ids.length === 1 ? <span>Send to Xero but not franchise</span> : <span>Send to Xero but not franchises</span>} 
				/>
			}
			<Button disabled={disable_button} variant='contained' onClick={handleSend}> send </Button>
		</INastixDialog>
	)
}


const ConfirmRoyaltiesSendDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> Send Invoice </Typography>
		</TableActionButon>
		<ConfirmRoyaltiesSendDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ConfirmRoyaltiesSendDialogHandler