import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import { TextField, Button, Typography, Autocomplete } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DialogFormSubHeadingTypography } from '@styles/Typography'

import { MessageContext } from '@contexts/MessageContext'
import { DialogButton, DialogFormControl } from '@styles/Dialog'
import { Child, ChildSex, ChildAuthorisation, Parent, School, Class } from '@utils/interfaces/interfaces'
import EmailActions from '@actions/EmailActions/emailActions'
import DatePickerObjectNastix from '@components/pickers/DatePickerObjectNastix'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { ParentsContext } from '@pages/Children/contexts/Parents'
import { SchoolsContext } from '@pages/Children/contexts/Schools'

import AllergyForm from './HandleAllergies'
import HandleSchools from './HandleSchools'
import HandleParents from './HandleParents'
import ParentDetails from './ParentDetails'
import ChildAuthorisationSelect from './ChildAuthorisationSelect'
import Form, { FormDataEntryType, OnSubmitCallback } from "../Form"
import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { NumOfChildrenNotAllocatedContext } from '@pages/Children/contexts/NumOfChildrenNotAllocated'
import ParentActions from '@actions/CRUDActions/ParentActions/parentActions'


enum PaymentStructure {
    YEARLY = 'yearly',
    TERMLY = 'termly',
    HALF_TERMLY = 'half termly',
    MONTHLY = 'monthly',
    PER_CLASS = 'per class'
}

const ChildForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const child_values = values as Child

    const { t } = useTranslation('child_form')

    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const {parents, setParents} = useContext(ParentsContext)

    const {schools} = useContext(SchoolsContext)
    const {setNumOfRequests} = useContext(NumOfRequestsContext)
	const {setNumOfChildrenNotAllocated} = useContext(NumOfChildrenNotAllocatedContext)

    const [first_name, setFirstName] = useState('')
    const [middle_name, setMiddleName] = useState('')
    const [last_name, setLastName] = useState('')

    const [gender, setGenderSelect] = useState('')
    const [child_authorisation_select, setChildAuthorisationSelect] = useState<ChildAuthorisation | string>('')
    const [allergies, setAllergies] = useState([''])
    const [preferred_contact, setPreferredContact] = useState('')
    const [child_parents, setChildParents] = useState<Parent[]>([])
    const [intial_parents, setInitialParents] = useState<Parent[] | null>(null)
    const [create_parent, setCreateParent] = useState(false)
    const [school, setSchool] = useState<School | null>(null)
    const [company_class, setCompanyClass] = useState<Class | null>(null)
    const [company_classes, setCompanyClasses] = useState<Class[]>([])
    const [shirt_size, setShirtSize] = useState('')
    const [birthday, setBirthday] = useState<Record<string, Dayjs | null>>({
		'birthday': null,
	})
    const [date_of_registration, setDateOfRegistration] = useState<Record<string, Dayjs | null>>({
		'date_of_registration': null,
	})
    const [start_date, setStartDate] = useState<Record<string, Dayjs | null>>({
		'start_date': null,
	})
    const [payment_structure, setPaymentStructure] = useState('')
    const [is_sponsored, setIsSponsored] = useState(false)
    const [sponsored_amount, setSponsoredAmount] = useState('')
    const [sponsored_reason, setSponsoredReason] = useState('')
    const [term_type, setTermType] = useState('termly')
    const [half_termly_type, setHalfTermlyType] = useState('half_termly')

    const [is_school_invoiced, setIsSchoolInvoiced] = useState(false)

    const [is_yearly_option, setIsYearlyOption] = useState(false)
    const [is_termly_option, setIsTermlyOption] = useState(false)
    const [is_monthly_option, setIsMonthlyOption] = useState(false)
    const [is_per_class_option, setIsPerClassOption] = useState(false)

    const [disable_submit_button, setDisableSubmitButton] = useState(false)

    //Used for setting child details based on existing child details
    useEffect(() => {
        if (child_values) {
            setFirstName(child_values.first_name)
            setMiddleName(child_values.middle_name ? child_values.middle_name : "")
            setLastName(child_values.last_name ? child_values.last_name : "")
            setGenderSelect(child_values.sex.toString())
            setChildAuthorisationSelect(child_values.authorized)
            setShirtSize(child_values.shirt_size)
            setPaymentStructure(child_values.payment_structure)
            setAllergies(child_values.allergies)
            if (child_values.is_sponsored) {
                setIsSponsored(child_values.is_sponsored)
                setSponsoredAmount(child_values.sponsored_amount)
                setSponsoredReason(child_values.reason_for_sponsor)
            } else {
                setIsSponsored(false)
                setSponsoredAmount('')
                setSponsoredReason('')
            }

            const dateObject = dayjs(child_values.birthday)
            setBirthday({'birthday': dateObject})
            const registered_date_object = dayjs(child_values.registered)
            setDateOfRegistration({'date_of_registration': registered_date_object})
            const start_date_object = dayjs(child_values.start_date)
            setStartDate({'start_date': start_date_object})

        }
    }, [child_values, change_company_rerender])

    useEffect(() => {
        if (child_values) {
            // console.log(child_values)
            // console.log(child_values.tenant.toString())
            // const filter = {"tenant__pk": child_values.tenant.toString()}
            // const school_actions = new SchoolActions()
            // school_actions.get(undefined, undefined, filter)
            // .then((school_response) => {
            //     setSchool(school_response)
            //     if (school_response.is_school_invoiced) {
            //         setIsSchoolInvoiced(true)
            //     } else {
            //         setIsSchoolInvoiced(false)
            //     }
            // })
            const filteredSchools = schools.find(obj => obj.tenant.toString() === child_values.tenant.toString())
            if (filteredSchools) {
                setSchool(filteredSchools)
                if (filteredSchools.is_school_invoiced) {
                    setIsSchoolInvoiced(true)
                } else {
                    setIsSchoolInvoiced(false)
                }
            }
        }
    }, [schools, child_values, change_company_rerender])

    useEffect(() => {
        if (child_values && !parents.length) {
            console.log(child_values)

            if (!child_values.parent.length) {
                return
            }

            const parent_actions = new ParentActions()
            parent_actions.get(undefined, undefined, {"ids": child_values.parent})
            .then((parent_data: Parent[]) => {
                if (parent_data.length) {
                    console.log(parent_data)
                    setInitialParents(parent_data)
                }
            })
        }
    }, [change_company_rerender, child_values, parents.length])

    useEffect(() => {
        if (intial_parents && intial_parents.length) {
            setParents(intial_parents)
            setChildParents(intial_parents)
        }
    }, [intial_parents, setParents])

    useEffect(() => {
        if (child_values && !intial_parents && parents.length) {
            const parent_actions = new ParentActions()
            console.log(child_values)
            if (!child_values.parent.length) {
                return
            }

            parent_actions.get(undefined, undefined, {"ids": child_values.parent})
            .then((parent_data: Parent[]) => {
                if (parent_data.length) {
                    setChildParents(parent_data)
                }
            })
        }
    }, [parents, child_values, intial_parents])

    useEffect(() => {
        if (child_values && child_parents.length) {
            setPreferredContact(child_parents[0].id.toString())
        }
    }, [child_parents, child_values])

    useEffect(() => {
        const company = getLocalStorageCompany()

        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            const finance = data.finance
            setIsYearlyOption(finance.allows_yearly_fees)
            setIsTermlyOption(finance.allows_termly_fees)
            setIsMonthlyOption(finance.allows_monthly_fees)
            setIsPerClassOption(finance.allows_per_class_fees)
        })
    }, [school, change_company_rerender])

    useEffect(() => {
        if (school !== null) {
            const franchisee_actions = new FranchiseeActions()
            franchisee_actions.getById(school.tenant.toString())
            .then(school_data => {
                switch (school_data.country_terms[0].toString()) {
                    case "1":
                        setTermType('termly_4');
                        setHalfTermlyType('half_termly_8')
                        break;
                    case "2":
                        setTermType('termly_3')
                        setHalfTermlyType('half_termly_6')
                        break;
                    default:
                        setTermType('international_termly')
                        setHalfTermlyType('international_half_termly')
                }   
            })
        } else {
            setTermType('termly')
            setHalfTermlyType('half_termly')
        }
    }, [school, change_company_rerender])

    useEffect(() => {
        setCompanyClass(null)
        setCompanyClasses([])

        if (school) {
            const class_actions = new ClassActions()
            class_actions.get(undefined, undefined, {school: school.id})
            .then((company_classes: Class[]) => {
                setCompanyClasses(company_classes)
            })
        }
    }, [school, child_values])

    useEffect(() => {
        if (child_values && child_values.company_class && child_values.company_class.length) {
            const class_actions = new ClassActions()
            class_actions.getById(child_values.company_class[0].toString())
            .then((company_class: Class) => {
                setCompanyClass(company_class)
            })
        }
    }, [child_values])

    const createParentClick = () => {
        if(create_parent)
            setCreateParent(false)
        else
            setCreateParent(true)
    }

    const onSelectSchoolCallback = (selected_school: School | null) => {
        setSchool(selected_school)
        if (selected_school) {
            if (selected_school.is_school_invoiced) {
                setIsSchoolInvoiced(true)
            } else {
                setIsSchoolInvoiced(false)
            }
        } else {
            setIsSchoolInvoiced(false)
        }
    }  

    const onSelectClass = (event: any, value: Class | null) => {
        setCompanyClass(value)
    }

    const onSelectParentCallback = (selected_parents: Parent[]) => {
        setChildParents(selected_parents)
        const preferred_parent = selected_parents.find(obj => obj.id.toString() === preferred_contact.toString())
        if (preferred_parent === undefined)
            setPreferredContact('')
    }  

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value)
    }

    const handleMiddleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMiddleName(event.target.value)
    }

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value)
    }

    const handleGenderChange = (event: SelectChangeEvent) => {
        setGenderSelect(event.target.value as string)
    }

    const handleChildAuthorisationChange = (event: SelectChangeEvent) => {
        setChildAuthorisationSelect(event.target.value as ChildAuthorisation)
    }

    const handlePreferredContactChange = (event: SelectChangeEvent) => {
        setPreferredContact(event.target.value as string)
    }

    const handlePaymentStructureChange = (event: SelectChangeEvent) => {
        setPaymentStructure(event.target.value as string)
    }

    const handleShirtChange = (event: SelectChangeEvent) => {
        setShirtSize(event.target.value as string)
    }

    const handleIsSponsoredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSponsored(event.target.checked)
    }

    const handleSponsoredAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSponsoredAmount(event.target.value)
    }
    const handleSponsoredReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSponsoredReason(event.target.value)
    }

    const onCreateParentCallback = (show_create: boolean, parent: Parent | null) => {
        setCreateParent(show_create)
        if (parent !== null) {
            const combined_parents = [ ...parents, parent]
            setParents(combined_parents)
            
            const combined_child_parents = [ ...child_parents, parent]
            setChildParents(combined_child_parents)
        }
    }  

    const handleEditSubmit = () => {
        const email_actions = new EmailActions()

        if (child_values.authorized.toString() !== ChildAuthorisation.UNAUTHORISED && child_authorisation_select === ChildAuthorisation.UNAUTHORISED) {
            email_actions.sendToUnauthorisedMember({child_id: child_values.id})
        }
        else if (child_values.authorized.toString() !== ChildAuthorisation.AUTHORISED && child_authorisation_select === ChildAuthorisation.AUTHORISED) {
            email_actions.sendToAuthorisedMember({child_id: child_values.id})
        }
        else if (child_values.authorized.toString() !== ChildAuthorisation.AWAITING_PAYMENT && child_authorisation_select === ChildAuthorisation.AWAITING_PAYMENT) {
            email_actions.sendToAwaitingPaymentMember({child_id: child_values.id})
        }
    }

    // const changed_to_suspended = child_values ? child_values.authorized.toString() !== ChildAuthorisation.UNAUTHORISED && child_authorisation_select === ChildAuthorisation.UNAUTHORISED : false

    const createFields = (values?: object) => {
        return (
            <DialogFormControl>
            <Grid container spacing={2}>
                <Grid item md={12} sx={{justifyContent: 'center', display: 'flex', marginTop: '1rem'}}>
                    <DialogFormSubHeadingTypography variant="h3"> {t('child_details')} </DialogFormSubHeadingTypography>
                </Grid>
                {child_values && <input type="hidden" value={child_values.id} id="id" name="id"/>}
                <Grid item md={6}>
                    <TextField 
                        id="first_name"
                        label={t('first_name')}
                        name="first_name"
                        variant="outlined"
                        onChange={handleFirstNameChange}
                        value={first_name}
                        sx={{marginBottom: 2}}
                        required
                        fullWidth
                    />
                    <TextField 
                        id="middle_name"
                        label={t('middle_name')}
                        name="middle_name"
                        variant="outlined"
                        onChange={handleMiddleNameChange}
                        value={middle_name}
                        sx={{marginBottom: 2}}
                        fullWidth
                    />
                    <TextField 
                        id="last_name"
                        label={t('last_name')}
                        name="last_name"
                        variant="outlined"
                        onChange={handleLastNameChange}
                        value={last_name}
                        sx={{marginBottom: 2}}
                        required
                        fullWidth
                    />
                    {!values ? 
                    <div>
                        <FormControl fullWidth>
                        <InputLabel id="shirt-select-label">{t('select_shirt_size')}</InputLabel>
                        <Select
                            labelId="shirt-select-label"
                            id="shirt-select"
                            name='shirt_size'
                            value={shirt_size}
                            label={t('select_shirt_size')}
                            onChange={handleShirtChange}
                            fullWidth
                            required
                        >
                            <MenuItem value={0}> 2-3 Months </MenuItem>
                            <MenuItem value={1}> 3-6 Months </MenuItem>
                            <MenuItem value={2}> 6-12 Months </MenuItem>
                            <MenuItem value={3}> 1-2 Years</MenuItem>
                            <MenuItem value={4}> 2-3 Years</MenuItem>
                            <MenuItem value={5}> 3-4 Years</MenuItem>
                            <MenuItem value={6}> 4-5 Years</MenuItem>
                            <MenuItem value={7}> 5-6 Years</MenuItem>
                            <MenuItem value={8}> 7-8 Years</MenuItem>
                            <MenuItem value={9}> 9-10 Years</MenuItem>
                            <MenuItem value={10}> 11-12 Years</MenuItem>
                            <MenuItem value={11}>{t('extra_small')}</MenuItem>
                            <MenuItem value={12}>{t('small')}</MenuItem>
                            <MenuItem value={13}>{t('medium')}</MenuItem>
                            <MenuItem value={14}>{t('large')}</MenuItem>
                            <MenuItem value={15}>{t('extra_large')}</MenuItem>
                        </Select>
                        </FormControl>
                        <br/>
                        <br/>
                        <FormControl fullWidth>
                            <InputLabel id="child-gender-select-label">{t('gender')}</InputLabel>
                            <Select
                                labelId="child-gender-select-label"
                                id="child-gender-select"
                                name="sex"
                                value={gender as string}
                                label={t('gender')}
                                onChange={handleGenderChange}
                                sx={{marginBottom: 2}}
                                required
                            >
                                <MenuItem value={ChildSex.BOY}>{t('boy')}</MenuItem>
                                <MenuItem value={ChildSex.GIRL}>{t('girl')}</MenuItem>
                                <MenuItem value={ChildSex.OTHER}>{t('other')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div> : null }
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                name='is_sponsored' 
                                checked={is_sponsored} 
                                onChange={handleIsSponsoredChange}
                            />
                        } 
                        label={t("is_sponsored")} 
                    />
                    {is_sponsored && 
                        <>
                        <TextField 
                            id="sponsored-amount"
                            label={t('sponsored_fee')} 
                            name="sponsored_amount"
                            variant="outlined"
                            type="number"
                            value={sponsored_amount}
                            onChange={handleSponsoredAmountChange}
                            inputProps={{ step: 0.01 }}
                            sx={{marginBottom: 2}}
                            required
                            fullWidth
                        />
                        <TextField 
                            id="reason-for-sponsor"
                            label={t('reason_for_sponsor')} 
                            name="reason_for_sponsor"
                            variant="outlined"
                            value={sponsored_reason}
                            onChange={handleSponsoredReasonChange}
                            sx={{marginBottom: 2}}
                            required
                            fullWidth
                        />
                        </>
                    }
                    <br/>
                    <DatePickerObjectNastix 
                        disabled={false}
                        dates={date_of_registration} 
                        setDates={setDateOfRegistration}
                        dates_key='date_of_registration'
                        label={t('date_of_registration')}
                        use_mobile={true}
                    /> 
                    <DatePickerObjectNastix 
                        disabled={false}
                        dates={start_date} 
                        setDates={setStartDate}
                        dates_key='start_date'
                        label={t('date_they_start_classes')}
                        use_mobile={true}
                    /> 
                    <AllergyForm allergy_values={allergies}/>
                </Grid>
                <Grid item md={6}>
                    <HandleSchools onSelectCallback={onSelectSchoolCallback} schools={schools} school={school}/>
                    <Autocomplete
					    value={company_class}
                        options={company_classes}
                        onChange={onSelectClass}
                        getOptionLabel={(option: Class) => option.name}
                        sx={{marginBottom: 2}}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Class" />}
                        isOptionEqualToValue={(option, value) => option.id === value.id} // Check if option's id is equal to the value's id
                    />
                    {!is_school_invoiced && <>
                        <FormControl fullWidth>
                            <InputLabel id="payment-structure-select-label">{t('preferred_payment_structure')}</InputLabel>
                            <Select
                                labelId="payment-structure-select-label"
                                id="payment-structure-select"
                                name="payment_structure"
                                value={payment_structure as string}
                                label={t('preferred_payment_structure')}
                                onChange={handlePaymentStructureChange}
                                sx={{marginBottom: 2}}
                                required
                            >
                                {is_yearly_option && <MenuItem value={PaymentStructure.YEARLY}>{t('yearly')}</MenuItem>}
                                {is_termly_option && <MenuItem value={PaymentStructure.TERMLY}>{t(term_type)}</MenuItem>}
                                {is_termly_option && <MenuItem value={PaymentStructure.HALF_TERMLY}>{t(half_termly_type)}</MenuItem>}
                                {is_monthly_option && <MenuItem value={PaymentStructure.MONTHLY}>{t('monthly')}</MenuItem>}
                                {is_per_class_option && <MenuItem value={PaymentStructure.PER_CLASS}>{t('per_class')}</MenuItem>}
                            </Select>
                        </FormControl>
                    </> }
                    <ChildAuthorisationSelect child_authorisation_select={child_authorisation_select} handleChildAuthorisationChange={handleChildAuthorisationChange}/>            
                    {/* {changed_to_suspended && <Alert severity="info" sx={{marginTop: '1rem'}}>Parents will be notified that their child is suspended.</Alert>} */}
                    <br/>
                    <DatePickerObjectNastix 
                        disabled={false}
                        dates={birthday} 
                        setDates={setBirthday} 
                        dates_key='birthday'
                        label={t('birthday')}
                        use_mobile={true}
                        max_date={dayjs()}
                    /> 
                    <br/>
                    { is_school_invoiced && <Typography sx={{border: '3px solid grey', borderRadius: '4px', padding: '4px', color: 'darkslategrey', textAlign: 'center', fontWeight: 'bold'}}> {t('parents_optional')} </Typography> }
                    <br/>
                    <HandleParents onSelectCallback={onSelectParentCallback} parents={parents} child_parents={child_parents}/>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircle/>}
                        onClick={createParentClick}
                        sx={{marginTop: 1, marginBottom: 3, padding: 1}}
                        fullWidth
                    >
                        {t('create_a_parent')}
                    </Button>
                    <FormControl sx={{minWidth: '100%'}}>
                        <InputLabel id="preferred-parent-select-label">{t('preferred_parent_contact')}</InputLabel>
                        <Select
                            labelId="preferred-parent-select-label"
                            id="preferred-parent-select"
                            name="preferred_parent_id"
                            value={preferred_contact}
                            label={t('preferred_parent_contact')}
                            onChange={handlePreferredContactChange}
                            sx={{marginBottom: 2}}
                            required={!is_school_invoiced}
                        >
                            {child_parents.map(parent => (
                                <MenuItem key={parent.id} value={parent.id}>{parent.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {child_values ? 
                <DialogButton variant='contained' type="submit" disabled={disable_submit_button} onClick={handleEditSubmit}> {t('edit')} </DialogButton>
                : <DialogButton variant='contained' type="submit" disabled={disable_submit_button}> {t('create')} </DialogButton>
            }
            </DialogFormControl>
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        setNumOfRequests(props => props + 1)
        setDisableSubmitButton(true)
        
        setMessage(props => ({...props, error: ""}))
        setMessage(props => ({...props, form_error: ""}))

        if (!school) {
            setMessage(props => ({...props, error: "Please select a school"}))
            return
        }

        if (child_values) {
            if (!child_values.company_class.length && company_class)
                setNumOfChildrenNotAllocated(props => props - 1)
            else if (child_values.company_class.length && !company_class)
                setNumOfChildrenNotAllocated(props => props + 1)
        }
        else {
            if (!company_class)
                setNumOfChildrenNotAllocated(props => props + 1)
        }

        //Deleting country id if its an empty string
        if (form_data_object['country_id'] === '') {
            delete form_data_object['country_id']
        }

        // convert dates to a more readible format
        const converted_birthday: Record<string, string> = {}
        Object.keys(birthday).forEach((key) => {
            const date_value = birthday[key]
            if (!date_value) {
                setMessage(props => ({...props, error: "Birthday cannot be empty"}))
                setDisableSubmitButton(false)
                return
            }
            else
                converted_birthday['birthday'] = date_value.format('YYYY-MM-DD')
        })
    
        // convert dates to a more readible format
        const converted_date_of_registration: Record<string, string> = {}
        Object.keys(date_of_registration).forEach((key) => {
            const date_value = date_of_registration[key]
            if (!date_value) {
                setMessage(props => ({...props, error: "Date of registration cannot be empty"}))
                setDisableSubmitButton(false)
                return
            }
            else
                converted_date_of_registration['registered'] = date_value.format('YYYY-MM-DD')
        })
    
        // convert dates to a more readible format
        const converted_start_date: Record<string, string> = {}
        Object.keys(start_date).forEach((key) => {
            const date_value = start_date[key]
            if (!date_value) {
                setMessage(props => ({...props, error: "Start date cannot be empty"}))
                setDisableSubmitButton(false)
                return
            }
            else
                converted_start_date['start_date'] = date_value.format('YYYY-MM-DD')
        })
        
        //Organizing the allergies in the right format
        let keysArray:string[] = [];
        let allergies_array:string[] = [];
        Object.keys(form_data_object).forEach(key => {
          keysArray.push(key);
        });
        const length = keysArray.length
        for (let i = length - 1; i > -1; i--) {
            if (keysArray[i].includes('allergy')) {
                let allergy_value = form_data_object[keysArray[i]].toString()
                if (allergy_value !== '')
                    allergies_array.push(allergy_value)
                delete form_data_object[keysArray[i]]
            }
        }

        //Change the format of class_type to what is required in the backend
        let class_type_object = {}
        if (school !== undefined && school !== null) {
            const class_type = school.school_type
            class_type_object = {
            [class_type]: true
            }
        }

        const allergies_object = {
            "allergies": allergies_array
        }
        const parent_object = {
            "parent": child_parents
        }
        
        const school_object: Record<string, string | null> = {
            'school_id': school? school.tenant.toString() : null,
        }
        
        const combined_object: {[x: string]: boolean | FormDataEntryValue| object} = {
            ...converted_birthday,
            ...converted_date_of_registration,
            ...converted_start_date,
            ...form_data_object,
            ...allergies_object,
            ...class_type_object,
            ...parent_object,
            ...school_object,
            ...(company_class ? { company_class: company_class.id } : {})
        }
        
        return onSubmitCallback(combined_object as FormDataEntryType)
        .then( () => {
            setFirstName('')
            setMiddleName('')
            setLastName('')
            setGenderSelect('')
            setChildAuthorisationSelect('')
            setShirtSize('')
            setPaymentStructure('')
            setAllergies([''])
            setIsSponsored(false)
            setSponsoredAmount('')
            setSponsoredReason('')
            setBirthday({'birthday': null})
            setDateOfRegistration({'date_of_registration': null})
            setStartDate({'start_date': null})
            setChildParents([])
            setPreferredContact('')
            setSchool(null)
        })
        .finally(() => {
            setNumOfRequests(props => props - 1)
            setDisableSubmitButton(false)
        })
    }
    
    return (
        <>
        <Grid container spacing={2} justifyContent={'center'}>
        {create_parent ? 
            <ParentDetails onCreateCallback={onCreateParentCallback}></ParentDetails>
            : null
        }
        </Grid>
        <Form onSubmitCallback={onSubmit} createFields={createFields}></Form>
        </>
    )
}

export default ChildForm