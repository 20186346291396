import { useEffect, useState } from "react"

import { List } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { TableActionButon } from "@styles/Buttons"
import { DialogDivider, DialogFormControl, INastixDialog, INastixDialogTitle } from "@styles/Dialog"

import RoyaltyActions from "@actions/Finance/RoyaltyActions/royaltyActions"
import { DialogComponentProps } from "@components/tables/Table/Table"
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { RoyaltyInvoice, RoyaltyLine } from "@utils/interfaces/interfaces";
import LineItemForm from "./HandleLineItem";
import RoyaltyInvoiceActions from "@actions/Finance/RoyaltyInvoiceActions/royaltyInvoiceActions";
import { useTranslation } from "react-i18next"


const EditRoyaltyInvoices = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    const [member_lines, setMemberLines] = useState<RoyaltyLine[]>([])
    const [invoice_lines, setInvoiceLines] = useState<RoyaltyInvoice[]>([])
    const [is_members, setIsMembers] = useState(false)
    const [is_invoices, setIsInvoices] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (ids.length > 0) {
            const id = ids[0]
            const invoice = state_manager.data.find(obj => obj.id === id)
            console.log(invoice)
            console.log(invoice.approved)
            if (invoice.approved)
                setDisabled(true)
            const royalty_actions = new RoyaltyActions()
            const royalty_invoice_actions = new RoyaltyInvoiceActions()
            if (invoice.invoice_type === 'Royalties from franchisees') {
                console.log('royalty invoices-')
                royalty_invoice_actions.get(undefined, undefined, {'franchisor_inv_id': id})
                .then((data: RoyaltyInvoice[]) => {
                    console.log(data)
                    setMemberLines([])
                    setInvoiceLines(data)
                    setIsInvoices(true)
                    setIsMembers(false)
                })
            } else {
                console.log('royalties-')
                royalty_actions.get(undefined, undefined, {'royalty_inv_id': id})
                .then((data: RoyaltyLine[]) => {
                    console.log(data)
                    setMemberLines(data)
                    setInvoiceLines([])
                    setIsMembers(true)
                    setIsInvoices(false)
                })
            }
        }
    }, [ids])

    const handleClose = () => {
		onClose()
	}

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle> Invoice Line Items </INastixDialogTitle>
            <DialogDivider color="black"/>
            {is_members ? 
                <>
                <DialogFormControl style={{ display: 'flex', flexDirection: 'row', marginTop: "2rem" }}>
                    <Typography variant="body1" fontSize="1.2rem" fontWeight="bold" style={{ marginRight: '10px', width: '250px' }}>
                        Child name
                    </Typography>
                    <Typography variant="body1" fontSize="1.2rem" fontWeight="bold" style={{ marginRight: '10px', width: '400px' }}>
                        Amount
                    </Typography>
                </DialogFormControl>
                <DialogDivider color="grey"/>
                </>
                : is_invoices ?
                    <>
                    <DialogFormControl style={{ display: 'flex', flexDirection: 'row', marginTop: "2rem" }}>
                        <Typography variant="body1" fontSize="1.2rem" fontWeight="bold" style={{ marginRight: '10px', width: '300px' }}>
                            Franchise name
                        </Typography>
                        <Typography variant="body1" fontSize="1.2rem" fontWeight="bold" style={{ marginRight: '10px', width: '400px' }}>
                            Amount
                        </Typography>
                    </DialogFormControl>
                    <DialogDivider color="grey"/>
                    </>
                    : <></>
            }
            {is_members ? member_lines.map((line_item, index) => (
                <div key={index}>
                    
                    <LineItemForm royalty_line_values={line_item} royalty_invoice_values={null} index={index} disabled={disabled} state_manager={state_manager}/>
                    <DialogDivider color="grey"/>
                </div>
            ))
            : is_invoices ? invoice_lines.map((line_item, index) => (
                <div key={index}>
                    
                    <LineItemForm royalty_line_values={null} royalty_invoice_values={line_item} index={index} disabled={disabled} state_manager={state_manager}/>
                    <DialogDivider color="grey"/>
                </div>
            ))
            : null
            }
        </INastixDialog>
    )
}

const EditRoyaltyInvoicesHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    const { t } = useTranslation('overview')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<List/>}>
                <Typography> {t('view_invoice')} </Typography>
            </TableActionButon>
            <EditRoyaltyInvoices ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
        </>
    )
}

export default EditRoyaltyInvoicesHandler